import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Slider from '@jsv2/components/Slider/Slider';
import { PlayButtonSvg } from '@components/PlayPauseButtonSvg';
import Image from '@jsv2/components/Image';
import VideoBlock from '@jsv2/components/Video/VideoBlock';

const SLIDER_SETTINGS = {
  navigation: true,
  slidesPerView: 1,
  className: 'media-reviews-slider__slider',
  style: {
    '--swiper-navigation-color': '#000',
    '--swiper-pagination-color': '#000',
    '--swiper-navigation-size': '34px',
  },
};

const SliderView = ({ reviews }) => (
  <div className="media-reviews-slider">
    <Slider sliderProps={{ ...SLIDER_SETTINGS }}>
      {reviews.map((item) => (
        <div className="reviews-item" key={item.id}>
          {item.video ? (
            <VideoBlock posterSrc={item.image} videoSrc={item.video} playButton={PlayButtonSvg} />
          ) : (
            <Image className="reviews-image" data={{ link: item.image }} background />
          )}
        </div>
      ))}
    </Slider>
  </div>
);

SliderView.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ErrorBoundaryDecorator()(SliderView);
