/* eslint-disable camelcase */
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '@jsv2/context/UserContext';
import SignUpContext from '@jsv2/context/SignUpContext';
import { registerUser } from '@jsv2/utils/UserUtils/APIRequest';

const BaseStep = ({ step, getNextStep, render }) => {
  const { updateUserRoles, updateUserData } = useContext(UserContext);
  const { urlParamsData } = useContext(SignUpContext);

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setError] = useState(null);

  const decodedUrlParamsData = urlParamsData ? JSON.parse(atob(urlParamsData)) : {};

  /**
   * @param data
   * @return {*|null}
   */
  const findEmail = (data) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (const item of data) {
      const email = item.answers.find((answer) => emailRegex.test(answer));

      if (email) {
        return email;
      }
    }

    return '';
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setError(null);
    setIsLoading(true);

    registerUser(values)
      .then(({ data }) => {
        actions.setSubmitting(false);
        getNextStep(step.onComplete());

        updateUserRoles(data).then(() => {
          setIsLoading(false);
        });
      })
      .catch(({ response }) => {
        setIsLoading(false);
        actions.setSubmitting(false);

        if (response.status !== 422) {
          setError(response.data.message);
        }

        if (response.data?.data) {
          actions.setErrors(response.data.data);
        }
      });
  };

  const handleSocialSignUp = ({ already_registered, full_name }) => {
    updateUserRoles();

    if (already_registered) {
      return window.location.reload();
    }

    if (full_name) {
      updateUserData('fullName', full_name);
    }

    return getNextStep(step.onComplete());
  };

  return render({
    isLoading,
    serverError,
    handleSubmit,
    handleSocialSignUp,
    emailFromQuiz:
      decodedUrlParamsData.answers?.length > 0 ? findEmail(decodedUrlParamsData.answers) : '',
  });
};

BaseStep.propTypes = {
  render: PropTypes.func.isRequired,
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default BaseStep;
