import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { IMAGE_SIZES } from '@js/constants';
import Image from '@jsv2/components/Image';
import { PlayButtonSvg } from '@components/PlayPauseButtonSvg';
import Slider from '@jsv2/components/Slider/Slider';
import VideoBlock from '@jsv2/components/Video/VideoBlock';
import { STORIES } from './config';

const SLIDER_SETTINGS = {
  navigation: true,
  slidesPerView: 1,
  className: 'stories-component__slider',
  autoHeight: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  },
};

const Stories = ({ stories }) => {
  const [isVideoShow, setIsVideoShow] = useState(false);

  return (
    <div className={`stories-component ${isVideoShow ? ' relative' : ''}`}>
      <div className={`stories-component__list ${isVideoShow ? 'video-show' : ''}`}>
        <Slider
          sliderProps={{
            ...SLIDER_SETTINGS,
          }}
        >
          {stories.map((story) => (
            <div key={story.id} className="stories-component__item">
              {story.videoSrc ? (
                <div className="stories-component__item-video">
                  <VideoBlock
                    posterSrc={story.posterSrc}
                    videoSrc={story.videoSrc}
                    playButton={PlayButtonSvg}
                    videoShowPropsHandler={setIsVideoShow}
                  />
                </div>
              ) : (
                <Image
                  className="stories-component__item-img"
                  data={{ link: story.posterSrc }}
                  config={{
                    size: IMAGE_SIZES.SMALL,
                  }}
                  background
                />
              )}

              <div className="stories-component__item-description">
                <h3>{story.name}</h3>

                <p>{story.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

Stories.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object),
};

Stories.defaultProps = {
  stories: STORIES,
};

export default ErrorBoundaryDecorator()(Stories);
