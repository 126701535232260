import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import Stories from '@jsv3/components/organisms/Stories';

const {
  templateVariables: { body_cta_text: bodyCtaText },
} = window;

const VideosSection = React.forwardRef(({ showBecomeButton, className, id }, ref) => {
  const { renderBecomeButton } = useContext(LPContext);
  const { editableFields } = useContext(EditFieldContext);

  return (
    <section className={`trending videos-section ${className}`} ref={ref} id={id}>
      <div className="main-container trending__container">
        <div className="trending__title">
          <div className="section-title section-title--center section-title--with-underline">
            <Editable fieldValue={editableFields.videos_heading} />
          </div>
          <div className="section-testimonial">
            <Editable fieldValue={editableFields.videos_testimonial} />
          </div>
        </div>

        <Stories />

        {showBecomeButton &&
          renderBecomeButton(
            'become-btn become-btn--with-border',
            bodyCtaText.content,
            'become-join-btn-4',
          )}
      </div>
    </section>
  );
});

VideosSection.propTypes = {
  showBecomeButton: PropTypes.bool,
  className: PropTypes.string,
};

VideosSection.defaultProps = {
  showBecomeButton: false,
  className: '',
};

VideosSection.displayName = 'VideosSection';

export default ErrorBoundaryDecorator()(VideosSection);
