export const REVIEWS = [
  {
    id: 1,
    image: '/images/collection/photo-reviews/1_1.png',
    video: null,
  },
  {
    id: 2,
    image: '/images/collection/photo-reviews/2_1.png',
    video: null,
  },
  {
    id: 3,
    image: '/images/collection/photo-reviews/3_1.png',
    video: null,
  },
  {
    id: 4,
    image: '/images/collection/photo-reviews/4_1.png',
    video: null,
  },
  {
    id: 5,
    image: '/images/collection/photo-reviews/5_1.png',
    video: null,
  },
  {
    id: 6,
    image: '/images/collection/photo-reviews/6_1.png',
    video: null,
  },
  {
    id: 7,
    image: '/images/collection/photo-reviews/7_1.png',
    video: null,
  },
  {
    id: 8,
    image: '/images/collection/photo-reviews/8_1.png',
    video: null,
  },
  {
    id: 9,
    image: '/images/collection/photo-reviews/9_1.png',
    video: null,
  },
  //
  {
    id: 10,
    image: '/images/collection/video-reviews/AnnaGretchen_preview.jpg',
    video: '/images/collection/video-reviews/AnnaGretchen.mp4',
  },
  {
    id: 11,
    image: '/images/collection/video-reviews/HeleneStella_preview.jpg',
    video: '/images/collection/video-reviews/HeleneStella.mp4',
  },
  {
    id: 12,
    image: '/images/collection/video-reviews/HeleneStella2_preview.jpg',
    video: '/images/collection/video-reviews/HeleneStella2.mp4',
  },
  {
    id: 13,
    image: '/images/collection/video-reviews/infilteredtravelers_preview.jpg',
    video: '/images/collection/video-reviews/infilteredtravelers.mp4',
  },
  {
    id: 14,
    image: '/images/collection/video-reviews/Jem208_preview.jpg',
    video: '/images/collection/video-reviews/Jem208.mp4',
  },
  {
    id: 15,
    image: '/images/collection/video-reviews/Joolie55_preview.jpg',
    video: '/images/collection/video-reviews/Joolie55.mp4',
  },
  {
    id: 16,
    image: '/images/collection/video-reviews/Latinachictravels_preview.jpg',
    video: '/images/collection/video-reviews/Latinachictravels.mp4',
  },
  {
    id: 17,
    image: '/images/collection/video-reviews/Mashiya_preview.jpg',
    video: '/images/collection/video-reviews/Mashiya.mp4',
  },
  {
    id: 18,
    image: '/images/collection/video-reviews/mpgellert_preview.jpg',
    video: '/images/collection/video-reviews/mpgellert.mp4',
  },
  {
    id: 19,
    image: '/images/collection/video-reviews/NavarroLucky_preview.jpg',
    video: '/images/collection/video-reviews/NavarroLucky.mp4',
  },
  {
    id: 20,
    image: '/images/collection/video-reviews/NavarroluckyNOSOUND_preview.jpg',
    video: '/images/collection/video-reviews/NavarroluckyNOSOUND.mp4',
  },
  {
    id: 21,
    image: '/images/collection/video-reviews/PlastikoPaulNOSOUND_preview.jpg',
    video: '/images/collection/video-reviews/PlastikoPaulNOSOUND.mp4',
  },
  {
    id: 22,
    image: '/images/collection/video-reviews/PrincessProShot_preview.jpg',
    video: '/images/collection/video-reviews/PrincessProShot.mp4',
  },
  {
    id: 23,
    image: '/images/collection/video-reviews/Thegreatjackgatsby_preview.jpg',
    video: '/images/collection/video-reviews/Thegreatjackgatsby.mp4',
  },
  {
    id: 24,
    image: '/images/collection/video-reviews/Wesley5388_preview.jpg',
    video: '/images/collection/video-reviews/Wesley5388.mp4',
  },
];
