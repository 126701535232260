import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import ReviewsSkeleton from '@jsv3/pages/ReviewsPage/UI/ReviewsSkeleton';
import GridView from './UI/GridView';
import SliderView from './UI/SliderView';
import { REVIEWS } from './config';

const MediaReviews = ({ reviews, isLoading, hideVideoOnMobile }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const [filteredReviews, setFilteredReviews] = useState([]);

  useEffect(() => {
    const preparedReviews = hideVideoOnMobile ? reviews.filter((review) => !review.video) : reviews;

    const videos = preparedReviews.filter((review) => review.video);
    const images = preparedReviews.filter((review) => !review.video);

    const rows = [];
    let imgIndex = 0;
    let vidIndex = 0;
    let rowType = 1;

    while (imgIndex < images.length || vidIndex < videos.length) {
      if (rowType === 1 && vidIndex < videos.length && imgIndex < images.length) {
        // row 1: video + image
        rows.push([videos[vidIndex], images[imgIndex]]);
        vidIndex++;
        imgIndex++;
        rowType = 2;
      } else if (rowType === 2 && imgIndex < images.length && vidIndex < videos.length) {
        // row 2: image + video
        rows.push([images[imgIndex], videos[vidIndex]]);
        imgIndex++;
        vidIndex++;
        rowType = 3;
      } else if (rowType === 3 && imgIndex + 2 < images.length) {
        // row 2: 3 images
        rows.push([images[imgIndex], images[imgIndex + 1], images[imgIndex + 2]]);
        imgIndex += 3;
        rowType = 1;
      } else {
        // other items
        const remaining = [];

        while (imgIndex < images.length) remaining.push(images[imgIndex++]);
        while (vidIndex < videos.length) remaining.push(videos[vidIndex++]);

        rows.push(remaining);
      }
    }

    setFilteredReviews(rows);
  }, []);

  if (!isMobile(screenTypeContext)) {
    return <GridView reviewsByRow={filteredReviews} isLoading={isLoading} />;
  }

  return isLoading ? <ReviewsSkeleton /> : <SliderView reviews={filteredReviews.flat()} />;
};

MediaReviews.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  hideVideoOnMobile: PropTypes.bool,
};

MediaReviews.defaultProps = {
  reviews: REVIEWS,
  isLoading: false,
  hideVideoOnMobile: false,
};

export default ErrorBoundaryDecorator()(MediaReviews);
