import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import { makeUserFreeMember } from '@jsv2/utils/UserUtils/APIRequest';
import BASE_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepDefault';
import ADDRESS_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/AddressStepDefault';
import PAYMENT_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/UpgradePlanPaymentStep';
import INCOMPLETE_SIGNUP_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/IncompleteSignUpMessage';
import PLAN_A_TRIP_THANKS_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/PlanATripThanksMessage';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const depositPaidFlowConfig = {
  [STEPS.BASE_STEP_DEFAULT]: {
    name: STEPS.BASE_STEP_DEFAULT,
    component: BASE_STEP_DEFAULT,
    onComplete: () => STEPS.ADDRESS_STEP_DEFAULT,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.BASE_STEP_DEFAULT],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.ADDRESS_STEP_DEFAULT]: {
    name: STEPS.ADDRESS_STEP_DEFAULT,
    component: ADDRESS_STEP_DEFAULT,
    onComplete: () => STEPS.PAYMENT_STEP_DEFAULT,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.ADDRESS_STEP_DEFAULT],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
    },
  },
  [STEPS.PAYMENT_STEP_DEFAULT]: {
    name: STEPS.PAYMENT_STEP_DEFAULT,
    component: PAYMENT_STEP_DEFAULT,
    onComplete: () => STEPS.PAID_THANKS_MESSAGE,
    onClose: () => {
      makeUserFreeMember('step_interrupted');
      return STEPS.INCOMPLETE_SIGNUP_MESSAGE;
    },
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.PAYMENT_STEP_DEFAULT],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: false,
    },
    prevStep: STEPS.ADDRESS_STEP_DEFAULT,
  },
  [STEPS.INCOMPLETE_SIGNUP_MESSAGE]: {
    name: STEPS.INCOMPLETE_SIGNUP_MESSAGE,
    component: INCOMPLETE_SIGNUP_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.INCOMPLETE_SIGNUP_MESSAGE],
    roles: {
      [ROLES.PAYMENT_STEP_INTERRUPTED]: true,
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
    prevStep: STEPS.PAYMENT_STEP_DEFAULT,
  },
  [STEPS.PAID_THANKS_MESSAGE]: {
    name: STEPS.PAID_THANKS_MESSAGE,
    component: PLAN_A_TRIP_THANKS_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.PAID_THANKS_MESSAGE],
    roles: {
      [ROLES.WAIT_LIST_MEMBER]: true,
    },
  },
};

export default depositPaidFlowConfig;
