import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Slider from '@jsv2/components/Slider/Slider';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import DynamicStarRating from '@jsv2/components/DynamicStarRating';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';

// Slider settings
const DEFAULT_SETTINGS = {
  navigation: true,
  slidesPerView: 1,
  className: 'reviews-gallery__slider',
  noSwiping: true,
  allowTouchMove: false,
  autoHeight: false,
  breakpoints: {
    767: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
  style: {
    '--swiper-navigation-color': '#000',
    '--swiper-pagination-color': '#000',
    '--swiper-navigation-size': '34px',
  },
};

// Slider settings
const gallerySettings = {
  navigation: true,
  slidesPerView: 1,
  className: 'reviews-gallery__images',
};

const NUMBER_OF_VISIBLE_SYMBOLS = 400;

/**
 * Reviews
 *
 * @param
 *
 * @return {*}
 *
 * @constructor
 */
const ReviewsWithBorder = ({ reviews, settings }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const renderGallery = (images) => (
    <Slider
      sliderProps={{
        ...gallerySettings,
      }}
    >
      {images.map((image) => (
        <div key={image.id} className="reviews-gallery__images-slide">
          <Image
            data={image}
            config={{
              size: isMobile(screenTypeContext) ? IMAGE_SIZES.IMAGE_W350 : IMAGE_SIZES.SMALL,
            }}
            background
          />
        </div>
      ))}
    </Slider>
  );

  if (reviews.length === 0) {
    return '';
  }

  return (
    <div className="reviews-gallery">
      <Slider sliderProps={{ ...settings }}>
        {reviews.map((item) => {
          const content =
            item.content.length > NUMBER_OF_VISIBLE_SYMBOLS
              ? item.content.slice(0, NUMBER_OF_VISIBLE_SYMBOLS).concat('...')
              : item.content;

          return (
            <div className="reviews-gallery__item" key={item.id}>
              <div className="reviews-gallery__item-rating">
                <DynamicStarRating rating={item.rating || 5} color="pink" />
              </div>

              {item.images.length > 0 && renderGallery(item.images)}

              <div className="reviews-gallery__item-title">{item.review_title}</div>

              <div className="reviews-gallery__item-content">
                "{content}" -{' '}
                <span className="reviews-gallery__item-author_info">
                  {item.reviewer_name}. {item.publisher_source}
                </span>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

ReviewsWithBorder.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.object,
};

ReviewsWithBorder.defaultProps = {
  settings: DEFAULT_SETTINGS,
};

export default ErrorBoundaryDecorator()(ReviewsWithBorder);
