import Summary from '@jsv2/models/membershipModels/Summary';
import { getInitialStep, getSignUpFlow, isEmailMissing } from '@jsv2/utils/flowUtils';
import SignUpConfig from '@jsv2/config/SignUp';

export default class SignUpProcess {
  constructor({ flow, membershipSummary, userRoles }) {
    this._userRoles = userRoles;
    this._flow = getSignUpFlow(flow);
    this._membershipSummary = membershipSummary ? new Summary(membershipSummary) : {};
    this._initialStep = getInitialStep({ flow: this.flow, userRoles, config: SignUpConfig });
    this._isEmailMissing = isEmailMissing();
    this._baseStepSidebarTagline = 'And upgrade your travels forever';
  }
  get userRoles() {
    return this._userRoles;
  }

  get flow() {
    return this._flow;
  }

  set flow(value) {
    this._flow = getSignUpFlow(value);
  }

  get membershipSummary() {
    return this._membershipSummary;
  }

  set membershipSummary(value) {
    this._membershipSummary = value ? new Summary(value) : {};
  }

  get initialStep() {
    return this._initialStep;
  }

  set initialStep(value) {
    this._initialStep = getInitialStep({
      flow: getSignUpFlow(value),
      userRoles: this._userRoles,
      config: SignUpConfig,
    });
  }

  get isEmailMissing() {
    return this._isEmailMissing;
  }

  get baseStepSidebarTagline() {
    return this._baseStepSidebarTagline;
  }

  set baseStepSidebarTagline(value) {
    this._baseStepSidebarTagline = value;
  }
}
