import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import Slider from '@jsv2/components/Slider/Slider';
import ContactUsModal from '@jsv3/components/organisms/ContactUsModal';
import Button from '@jsv3/components/atoms/Button';
import FeatureCard from './FeatureCard';
import Quote from '../Quote';

const sliderSettings = {
  slidesPerView: 1.1,
  navigation: true,
  className: 'features-grid__slider lp-swiper',
};

const FeaturesGrid = ({ id, idToScroll }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { editableFields } = useContext(EditFieldContext);
  const screenTypeContext = useContext(ScreenTypeContext);
  const { scrollTo } = useContext(LPContext);

  const benefits = [
    {
      id: 1,
      title: editableFields.features_grid_feature_1_title,
      description: editableFields.features_grid_feature_1_description,
    },
    {
      id: 2,
      title: editableFields.features_grid_feature_2_title,
      description: editableFields.features_grid_feature_2_description,
    },
    {
      id: 3,
      title: editableFields.features_grid_feature_3_title,
      description: editableFields.features_grid_feature_3_description,
    },
    {
      id: 4,
      title: editableFields.features_grid_feature_4_title,
      description: editableFields.features_grid_feature_4_description,
    },
    {
      id: 5,
      title: editableFields.features_grid_feature_5_title,
      description: editableFields.features_grid_feature_5_description,
    },
    {
      id: 6,
      title: editableFields.features_grid_feature_6_title,
      description: editableFields.features_grid_feature_6_description,
    },
  ];

  const renderFeatures = () =>
    benefits.map((feature, index) => (
      <FeatureCard
        key={feature.id}
        title={feature.title}
        description={feature.description}
        index={index + 1}
      />
    ));

  const renderMobileView = () => (
    <Slider
      sliderProps={{
        ...sliderSettings,
      }}
    >
      {renderFeatures()}
    </Slider>
  );

  const renderActions = () => (
    <div className="actions-wrapper-flex">
      {idToScroll && (
        <Button
          className="become-btn become-btn--red btn-no-align btn-rounded"
          dataQaId="qa_get_started_btn"
          onClick={() => scrollTo(idToScroll)}
        >
          Get started
        </Button>
      )}

      {/*<Button
        className="become-btn become-btn--black btn-no-align btn-rounded"
        dataQaId="qa_call_back_btn"
        onClick={() => setIsModalOpened(true)}
      >
        request a call back
      </Button>*/}
    </div>
  );

  return (
    <section id={id} className="features-grid pt-50 pb-50">
      <div className="small-container">
        <div className="section-title section-title--center section-title--with-underline section-title--mobile-left">
          <Editable fieldValue={editableFields.features_grid_title} />
        </div>

        {editableFields.features_grid_description && (
          <div className="section-description section-description--center section-description--font-weigth-400 section-description--mobile-left fsz-20">
            <Editable fieldValue={editableFields.features_grid_description} />
          </div>
        )}

        {renderActions()}

        <h4 className="calluna-font">
          <Editable fieldValue={editableFields.features_grid_tagline} />
        </h4>

        {isMobile(screenTypeContext) ? (
          renderMobileView()
        ) : (
          <div className="features-grid__wrapper">{renderFeatures()}</div>
        )}

        <Quote id="quote_in_features" quoteVariable="features_grid_quote" />

        {renderActions()}
      </div>

      <ContactUsModal isModalOpened={isModalOpened} onClose={() => setIsModalOpened(false)} />
    </section>
  );
};

FeaturesGrid.propTypes = {
  id: PropTypes.string.isRequired,
  idToScroll: PropTypes.string.isRequired,
};

export default ErrorBoundaryDecorator()(FeaturesGrid);
