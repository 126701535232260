import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';

import AddressStepForm from '@jsv2/components/Authorization/AddressStepForm';
import LabelComponent from '@jsv2/components/Common/LabelComponent';
import PhoneInputAdapter from '@components/formComponents/PhoneFormikAdapter';
import UserContext from '@jsv2/context/UserContext';

const { currentCountry } = window.__SERVER_DATA__.shared_data;

const StepForm = ({ handleSubmit }) => {
  const { fullName, countryId, zip, phone } = useContext(UserContext).customer;

  const form = useRef(null);
  const initialValues = {
    phone: phone || '',
    zip: zip || '',
    full_name: fullName || '',
    country_id: countryId || currentCountry,
  };

  return (
    <AddressStepForm
      formRef={form}
      render={({
        apiThrottleClient,
        fullNameField,
        formData,
        handleFieldChange,
        handleCountryIdChange,
        getZipLabel,
      }) => (
        <Formik
          innerRef={(node) => (form.current = node)}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validateOn={false}
          onSubmit={(values, actions) => {
            apiThrottleClient.cancelPrevRequestToken();
            handleSubmit(values, actions);
          }}
        >
          {({ isSubmitting, errors, values }) => (
            <Form className="signup-step signup-step-2">
              <div className="input-group">
                <div className="field-wrapper">
                  <div className="input">
                    <LabelComponent
                      name="full_name"
                      errors={errors}
                      labelText={t('full name')}
                      dataQaId="qa_full_name_error"
                    />

                    <Field
                      name="full_name"
                      type="text"
                      placeholder="Jane Doe"
                      onChange={(event) => handleFieldChange(event, 'full_name', values)}
                      innerRef={fullNameField}
                      data-qa-id="full_name_field"
                    />
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="input">
                    <LabelComponent
                      name="country_id"
                      errors={errors}
                      labelText={t('country')}
                      dataQaId="qa_country_error"
                    />

                    <Field
                      name="country_id"
                      autoComplete="country"
                      component="select"
                      className="select"
                      onChange={(event) => handleCountryIdChange(event, values)}
                      data-qa-id="country_id_select"
                    >
                      {formData.countries?.map(({ id, name }) => (
                        <option key={id} value={id} data-qa-id="country_dropdown_item">
                          {' '}
                          {name}{' '}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="input">
                    <LabelComponent
                      name="zip"
                      errors={errors}
                      labelText={`${getZipLabel(values.country_id)} code`}
                      dataQaId="qa_zip_error"
                    />

                    <Field
                      name="zip"
                      autoComplete="postal-code"
                      type="text"
                      placeholder="XXXXX"
                      onChange={(event) => handleFieldChange(event, 'zip', values)}
                      data-qa-id="postal-code_field"
                    />
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="input">
                    <LabelComponent
                      name="phone"
                      errors={errors}
                      labelText={t('phone number')}
                      dataQaId="qa_phone_error"
                    />

                    <Field
                      name="phone"
                      country={formData.phoneCode}
                      enableSearchField
                      component={PhoneInputAdapter}
                      onlyCountries={formData.countriesISO2}
                      inputProps={{
                        id: 'phone-input',
                        name: 'phone',
                        inputMode: 'tel',
                        'data-qa-id': 'phone_field',
                      }}
                    />
                  </div>
                </div>
              </div>

              <button
                className="button-pink"
                type="submit"
                disabled={isSubmitting}
                data-qa-id="continue_button"
              >
                {t('continue')}
              </button>
            </Form>
          )}
        </Formik>
      )}
    />
  );
};

StepForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default StepForm;
