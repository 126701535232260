import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { PlayButtonSvg } from '@components/PlayPauseButtonSvg';
import Image from '@jsv2/components/Image';
import NativeVideoComponent from '@jsv2/components/Video/NativeVideoComponent';
import Button from '@jsv3/components/atoms/Button';
import GridSkeleton from './GridSkeleton';

const GridView = ({ reviewsByRow, isLoading }) => {
  const [visibleRows, setVisibleRows] = useState(2);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageHandler = (image) => {
    setIsImageOpen(true);
    setSelectedImage(image);
  };

  if (isLoading) {
    return <GridSkeleton />;
  }

  /**
   * @param data
   * @return {array}
   */
  const getVideoCellClass = (data) => {
    if (data.filter((item) => item.video).length > 1) {
      return 'reviews-item-video reviews-item-video--w50';
    }

    return 'reviews-item-video';
  };

  return (
    <>
      <div className="reviews-grid">
        {reviewsByRow.slice(0, visibleRows).map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="reviews-row">
            {row.map((item) => (
              <div
                key={item.id}
                className={`reviews-item ${
                  item.video ? getVideoCellClass(row) : 'reviews-item-image'
                }`}
              >
                {item.video ? (
                  <NativeVideoComponent
                    posterSrc={item.image}
                    videoSrc={item.video}
                    playButton={PlayButtonSvg}
                  />
                ) : (
                  <Image
                    className="reviews-image"
                    data={{ link: item.image }}
                    background
                    onClick={() => openImageHandler(item.image)}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      {visibleRows < reviewsByRow.length && (
        <Button
          className="btn-red btn-middle btn-rounded mt-30 section-btn"
          dataQaId="qa_view_more_reviews"
          onClick={() => setVisibleRows((prev) => prev + 1)}
        >
          show more reviews
        </Button>
      )}

      {isImageOpen && selectedImage && (
        <Lightbox
          mainSrc={selectedImage}
          onCloseRequest={() => {
            setIsImageOpen(false);
          }}
        />
      )}
    </>
  );
};

GridView.propTypes = {
  reviewsByRow: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  isLoading: PropTypes.bool,
};

GridView.defaultProps = {
  isLoading: false,
};

export default ErrorBoundaryDecorator()(GridView);
