import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { ErrorMessage, FieldArray } from 'formik';
import CustomField from '@components/formComponents/CustomField';

const PLACEHOLDER = 'Type your answer here...';

/**
 * @param {object} question
 * @param {object} values
 * @param {string} error
 * @param {number} questionIndex
 * @param {boolean} isSingleSubmit
 * @param {boolean} autoResizeField
 * @return {JSX.Element}
 * @constructor
 */
const InputQuestion = ({
  question,
  values,
  error,
  questionIndex,
  autoResizeField,
  isSingleSubmit,
}) => {
  const questionName = isSingleSubmit ? 'answers' : `answers[${questionIndex}].answers`;

  const handleInput = (e) => {
    if (autoResizeField) {
      const textarea = e.target;
      const lineHeight = 33;

      textarea.style.height = `${lineHeight}px`;

      const contentHeight = textarea.scrollHeight;
      const minHeight = 1.5 * lineHeight;

      if (contentHeight > minHeight) {
        textarea.style.height = `${Math.min(contentHeight, 4.5 * lineHeight)}px`;
      } else {
        textarea.style.height = `${minHeight}px`;
      }

      if (contentHeight > 4.5 * lineHeight) {
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  return (
    <div className="input form-field" key={question.question}>
      {question.hint && (
        <div className="question-hint" data-qa-id="question_hint">
          {question.hint}
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      <FieldArray
        name={questionName}
        render={(arrayHelpers) =>
          question.options.length > 0 ? (
            question.options.map((answer, index) => (
              <CustomField
                key={answer.hint}
                as={autoResizeField ? 'textarea' : 'input'}
                name={`values.${questionName}[${index}]`}
                type="text"
                autoComplete="off"
                placeholder={answer.hint || PLACEHOLDER}
                value={values.answers[questionIndex].answers[index] || ''}
                onChange={(e) => arrayHelpers.replace(index, e.target.value)}
                onInput={handleInput}
              />
            ))
          ) : (
            <CustomField
              name={`values.${questionName}[0]`}
              as={autoResizeField ? 'textarea' : 'input'}
              type="text"
              autoComplete="off"
              placeholder={question.placeholder || PLACEHOLDER}
              onChange={(e) => arrayHelpers.replace(0, e.target.value)}
              onInput={handleInput}
            />
          )
        }
      />

      <ErrorMessage name={question.type} />
    </div>
  );
};

InputQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  error: PropTypes.string,
  isSingleSubmit: PropTypes.bool,
  autoResizeField: PropTypes.bool,
};

InputQuestion.defaultProps = {
  error: '',
  isSingleSubmit: false,
  autoResizeField: false,
};

export default ErrorBoundaryDecorator()(InputQuestion);
