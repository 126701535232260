export const DESKTOP_SCREEN_WIDTH = 1920;
export const TABLET_SCREEN_WIDTH = 1195;
export const SMALL_TABLET_SCREEN_WIDTH = 1024;
export const MOBILE_SCREEN_WIDTH = 768;
export const MINI_SCREEN_WIDTH = 375;

export const IMAGE_SIZES = {
  EXTRA_LARGE: 1920,
  LARGE: 1440,
  MEDIUM: 900,
  SMALL: 600,
  IMAGE_W350: 350,
  IMAGE_W150: 150,
  IMAGE_W100: 100,
};

export const SCREEN_TYPES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  MINI: 'mini',
};
