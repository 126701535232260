export const STORIES = [
  {
    id: 1,
    videoSrc: '/lp-videos/lp-video-1.mp4',
    posterSrc: '/images/lp/promo5/video-images/video-preview-1.jpeg',
    name: 'Iru Veli, Maldives',
    description: 'with VIP Traveler Ivonne Moralis',
  },
  {
    id: 2,
    videoSrc: '/lp-videos/the-inside-word-video-3.mp4',
    posterSrc: '/images/lp/promo3/video-images/the-inside-word-video-3-compressed.jpg',
    name: 'Grand Hyatt, Playa Del Carmen',
    description: 'with Destination Expert Tiffany',
  },
  {
    id: 3,
    videoSrc: '/lp-videos/lp-video-3.mp4',
    posterSrc: '/images/lp/promo5/video-images/video-preview-3.jpeg',
    name: 'Sky Safari Africa',
    description: 'with Gen. Manager Mia Lawson',
  },
  {
    id: 4,
    videoSrc: '/lp-videos/lp-video-4.mp4',
    posterSrc: '/images/lp/promo5/video-images/video-preview-4.jpeg',
    name: 'Samaya Ubud, Bali',
    description: 'with Destination Expert Ruby',
  },
  {
    id: 5,
    videoSrc: '/lp-videos/lp-video-5.mp4',
    posterSrc: '/images/lp/promo5/video-images/video-preview-5.jpeg',
    name: 'Amanyara Turks & Caicos',
    description: 'with Gen. Manager Ian White',
  },
  {
    id: 6,
    videoSrc: '/lp-videos/lp-video-6.mp4',
    posterSrc: '/images/lp/promo5/video-images/video-preview-6.jpeg',
    name: 'JW Marriott Cancun',
    description: 'with Gen. Manager Tiffany',
  },
];
