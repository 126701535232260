import React, { useContext, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Cookies from 'browser-cookies';

import BaseStepForm from '@jsv2/components/Authorization/BaseStepForm';
import LabelComponent from '@jsv2/components/Common/LabelComponent';
import SocialNetworks from '@components/Auth/SocialNetworks';
import PasswordField from '@jsv2/components/Common/PasswordField';
import { allSocialButtons, facebookSocialButton } from '@components/Auth/SocialButtons/Buttons';
import { isFacebookUrl } from '@jsv3/utils/urlUtils';
import OAuthUserEmail from '@components/Auth/OAuthUserEmail';
import { eraseSessionCookie } from '@utils/user';
import SignUpContext from '@jsv2/context/SignUpContext';

const StepForm = ({ handleSubmit, handleSocialSignUp, emailFromQuiz }) => {
  const { signUpProcess, logInUrl } = useContext(SignUpContext);
  const { isEmailMissing, membershipSummary, flow } = signUpProcess;

  const { promoCampaignId } = membershipSummary;

  const [isEmailModalOpen, setEmailModalOpen] = useState(isEmailMissing);

  const initialValues = {
    email: Cookies.get('remember-logged-out-user-email') || emailFromQuiz || '',
    password: '',
    promo_campaign_id: promoCampaignId,
    signup_flow: flow,
  };

  return (
    <BaseStepForm
      render={({ handleChange }) => (
        <>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnChange={false}>
            {({ setFieldValue, setFieldError, isSubmitting, errors }) => (
              <Form className="signup-step-1">
                <div className="input-group">
                  <div className="field-wrapper">
                    <div className="input">
                      <LabelComponent
                        name="email"
                        errors={errors}
                        labelText={t('Your Email:')}
                        dataQaId="qa_email_error"
                      />

                      <Field
                        type="email"
                        inputMode="email"
                        name="email"
                        autoComplete="email"
                        placeholder="john@example.com"
                        onBlur={() => {}}
                        onChange={(e) => handleChange(e, setFieldValue, setFieldError)}
                        data-qa-id="email_field"
                      />
                    </div>
                  </div>

                  <div className="field-wrapper">
                    <div className="input">
                      <LabelComponent
                        name="password"
                        errors={errors}
                        labelText={t('Create Password:')}
                        dataQaId="qa_pass_error"
                      />

                      <PasswordField
                        label="password"
                        autoComplete="new-password"
                        onBlur={() => {}}
                        onChange={(e) => handleChange(e, setFieldValue, setFieldError)}
                        data-qa-id="password_field"
                      />
                    </div>
                  </div>
                </div>

                <button
                  className="button-pink"
                  type="submit"
                  disabled={isSubmitting}
                  data-qa-id="sign_up_button"
                >
                  {t('CONTINUE')}
                </button>
              </Form>
            )}
          </Formik>

          <p className="login-link-wrapper">
            {t('Already a member?')}{' '}
            <a href={logInUrl} className="button-link login-link" data-qa-id="login_link">
              {t('Log in')}
            </a>
          </p>

          <p className="or-step">{window.lang.get('common.or')}</p>

          <p>{t('Create account using Google')}</p>

          <SocialNetworks
            buttons={isFacebookUrl() ? facebookSocialButton : allSocialButtons}
            flow={flow}
            promoCampaignId={promoCampaignId}
          />

          <OAuthUserEmail
            isOpen={isEmailModalOpen}
            onSuccess={handleSocialSignUp}
            onCancel={() => {
              setEmailModalOpen(false);
              eraseSessionCookie();
            }}
          />
        </>
      )}
    />
  );
};

export default StepForm;
