import {
  MOBILE_SCREEN_WIDTH,
  TABLET_SCREEN_WIDTH,
  MINI_SCREEN_WIDTH,
  SCREEN_TYPES,
} from '@jsv3/enums/deviceScreenEnums';

export const getScreenWidth = () => window.innerWidth;

export const isDesktopWidth = () => getScreenWidth() > TABLET_SCREEN_WIDTH;

export const isTabletWidth = () =>
  getScreenWidth() <= TABLET_SCREEN_WIDTH && getScreenWidth() >= MOBILE_SCREEN_WIDTH;

export const isMobileWidth = () => getScreenWidth() < MOBILE_SCREEN_WIDTH;

export const isMiniScreen = () => getScreenWidth() < MINI_SCREEN_WIDTH;

export const isBiggerThanMobile = () => getScreenWidth() >= MOBILE_SCREEN_WIDTH;

export const getCurrentScreenType = () => {
  if (isDesktopWidth()) {
    return SCREEN_TYPES.DESKTOP;
  }
  if (isTabletWidth()) {
    return SCREEN_TYPES.TABLET;
  }
  if (isMobileWidth()) {
    return SCREEN_TYPES.MOBILE;
  }
  return SCREEN_TYPES.MINI;
};
