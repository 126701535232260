import React from 'react';

import SkeletonLoadingItem from '@jsv3/components/atoms/SkeletonLoadingItem';

const GridSkeleton = () => (
  <div className="reviews-grid">
    <div className="reviews-row">
      <SkeletonLoadingItem className="reviews-item reviews-item-video" />
      <SkeletonLoadingItem className="reviews-item" />
    </div>

    <div className="reviews-row">
      <SkeletonLoadingItem className="reviews-item" />
      <SkeletonLoadingItem className="reviews-item reviews-item-video" />
    </div>
  </div>
);

export default GridSkeleton;
