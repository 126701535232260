import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Button from '@jsv3/components/atoms/Button';

const BaseMessageTemplate = ({
  handleClick,
  handleSecondButtonClick,
  image,
  heading,
  tagline,
  buttonText,
  secondButtonText,
  signature,
  taglineSecondLine,
  customClassName,
  showBackButton,
  backButtonText,
  goBackHandler,
}) => (
  <div
    className={`viptraveler-modal viptraveler-modal--welcome-msg ${classNames(customClassName)}`}
  >
    {showBackButton && (
      <button
        type="button"
        className="msg-back-btn"
        data-qa-id="qa_go_back_step"
        onClick={goBackHandler}
      >
        {backButtonText}
      </button>
    )}

    <div className="greet-message" data-qa-id="greet-message">
      <div className="wrapper">
        <img src={image} alt="" className="sidebar__image" />

        <h2 className="heading with-underline" data-qa-id="greet-message-title">
          {heading}
        </h2>

        <p className="tagline">{tagline}</p>

        {taglineSecondLine && <p className="tagline">{taglineSecondLine}</p>}

        {signature && <p className="signature">{signature}</p>}

        <div className={`greet-message__actions${secondButtonText ? ' multiple' : ''}`}>
          <Button
            type="button"
            className="btn-red"
            dataQaId="get_started_button"
            onClick={handleClick}
          >
            {buttonText}
          </Button>

          {secondButtonText && (
            <Button
              type="button"
              className="btn-black"
              dataQaId="get_started_button_2"
              onClick={handleSecondButtonClick}
            >
              {secondButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  </div>
);
BaseMessageTemplate.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleSecondButtonClick: PropTypes.func,
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  secondButtonText: PropTypes.string,
  signature: PropTypes.string,
  taglineSecondLine: PropTypes.string,
  customClassName: PropTypes.string,
  backButtonText: PropTypes.string,
  showBackButton: PropTypes.bool,
  goBackHandler: PropTypes.func,
};

BaseMessageTemplate.defaultProps = {
  signature: '',
  taglineSecondLine: '',
  customClassName: '',
  secondButtonText: null,
  backButtonText: '',
  showBackButton: false,
  handleSecondButtonClick: () => {},
  goBackHandler: () => {},
};

export default ErrorBoundaryDecorator()(BaseMessageTemplate);
