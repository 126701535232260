import React from 'react';
import PropTypes from 'prop-types';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import AuthMessage from '@jsv2/components/Authorization/AuthMessage';

/**
 * Pre Q Pop A (Abandoned).
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/glasses.svg',
  heading: 'No worries! We get it. You want to keep things casual.',
  tagline: 'Stay in the loop with our free membership and enjoy access to exclusive content.',
  buttonText: 'VIEW VIP EXCLUSIVES™',
  backButtonText: 'back to payment details',
};

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const IncompleteSignUpMessage = ({ step, getNextStep }) => {
  const goBackHandler = () => {
    if (step.prevStep) {
      getNextStep(step.prevStep);
    }
  };

  const handleClick = () => {
    window.location.href = defaultHomePageUrl;
  };

  return (
    <AuthMessage
      step={step}
      getNextStep={getNextStep}
      render={() => (
        <BaseMessageTemplate
          handleClick={handleClick}
          showBackButton={!!step.prevStep}
          goBackHandler={goBackHandler}
          {...MESSAGE_CONTENT}
        />
      )}
    />
  );
};

IncompleteSignUpMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default IncompleteSignUpMessage;
