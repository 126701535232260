import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import OverlaySpinner from '@jsv3/components/molecules/OverlaySpinner';
import SignUpContext from '@jsv2/context/SignUpContext';
import PaymentStep from '@jsv2/components/Authorization/PaymentStepDefault';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import StepForm from './StepForm';
import MembershipDefault from '../../StepSidebars/MembershipDefault';

const PaymentStepDefault = ({ step, getNextStep, hideProgressBar }) => {
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const { membershipPlanId } = membershipSummary;

  return (
    // TODO resolve component names
    <PaymentStep
      step={step}
      getNextStep={getNextStep}
      render={({ isLoading, error, handleSubmit }) => (
        <div className="viptraveler-modal payment-step">
          <div className="step-wrapper">
            <MembershipDefault hideProgressBar={hideProgressBar} />

            <div className="signup signup--step3">
              {isLoading && <OverlaySpinner isAbsolute />}

              {!hideProgressBar && <ProgressBar activeStep={3} />}

              {error?.message && (
                <div className="label-content--error mt-20"> {error?.message} </div>
              )}

              <div className="summary" data-plan-id={membershipPlanId}>
                <StepForm handleSubmit={handleSubmit} errors={error?.data} />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

PaymentStepDefault.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
  hideProgressBar: PropTypes.bool,
};

PaymentStepDefault.defaultProps = {
  hideProgressBar: false,
};

export default PaymentStepDefault;
