import React from 'react';
import _ from 'lodash';

import SkeletonLoadingItem from '@jsv3/components/atoms/SkeletonLoadingItem';

const ReviewsSkeleton = () => (
  <div className="d-flex flex-vertical-center pt-40 reviews-page__skeleton">
    {_.times(3, (i) => (
      <div key={i} className="ml-10 mr-10 p-20 border-1-gray">
        <SkeletonLoadingItem width="40%" height="20px" className="mb-20" />
        <SkeletonLoadingItem width="100%" height="20px" className="mb-10" />
        <SkeletonLoadingItem width="100%" height="20px" className="mb-10" />
        <SkeletonLoadingItem width="100%" height="20px" className="mb-10" />
        <SkeletonLoadingItem width="100%" height="20px" className="mb-10" />
        <SkeletonLoadingItem width="100%" height="20px" className="mb-10" />
        <SkeletonLoadingItem width="100%" height="20px" className="mb-10" />
      </div>
    ))}
  </div>
);

export default ReviewsSkeleton;
