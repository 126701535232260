import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { getCurrentScreenType } from '@jsv3/utils/deviceScreenUtils';
import { SCREEN_TYPES } from '@jsv3/enums/deviceScreenEnums';

export const isDesktop = (currentScreenType) => currentScreenType === SCREEN_TYPES.DESKTOP;

export const isTablet = (currentScreenType) => currentScreenType === SCREEN_TYPES.TABLET;

export const isMobile = (currentScreenType) => currentScreenType === SCREEN_TYPES.MOBILE;

export const isMiniScreen = (currentScreenType) => currentScreenType === SCREEN_TYPES.MINI;

export const selectTheAppropriateSize = (
  currentScreenType,
  desktop = 1920,
  tablet = 900,
  mobile = 600,
) => {
  const sizes = {
    desktop,
    tablet,
    mobile,
  };

  return sizes[currentScreenType];
};

const ScreenTypeContext = React.createContext({});

export const ScreenTypeContextProvider = ({ children }) => {
  const [screenType, setScreenType] = useState(getCurrentScreenType());

  /**
   * Add browser screen size by height to css variable (used in css)
   */
  const setScreenHeight = () => {
    const screenHeight = window.innerHeight + 'px';
    const rootPageElement = document.getElementsByTagName('html')[0] || null;

    if (rootPageElement) {
      rootPageElement.style.setProperty('--screen-height', screenHeight);
    }
  };

  /**
   * Handles "resize" checking current screen type and screen height (debounced)
   */
  const onResizeHandler = _.debounce(() => {
    const currentScreenType = getCurrentScreenType();

    setScreenType(currentScreenType);

    setScreenHeight();
  }, 250);

  useEffect(() => {
    setScreenHeight();

    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  return <ScreenTypeContext.Provider value={screenType}>{children}</ScreenTypeContext.Provider>;
};

export default ScreenTypeContext;
